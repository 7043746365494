import React, { useEffect } from "react";
import store, { addCurrentStock } from "../../store";
import { getFutureStock } from "../../api/single-item-api";
import { connect } from "react-redux";
import {
  SpanBGeneral,
  SpanLGeneral,
  PGeneral,
} from "../../configurations/general-conf";
import { getWhname, getStockTD } from "./utilities";
import moment from "moment";

const FutureStock = (props) => {
  useEffect(() => {
    const { uuid } = props.match.params;
    // console.log(uuid);

    !store.getState()["futureStock" + uuid] &&
      addCurrentStock(store, "futureStock" + uuid);
    
    !store.getState()["planStock" + uuid] &&
      addCurrentStock(store, "planStock" + uuid);

    uuid && getFutureStock(uuid);
  }, [props.match.params]);

  // console.log(props);
  const { stock, data, planStock } = props;
  const currentStockPacks = data.currentStock?.reduce((a, b) => {
    return a + b;
  }, 0);
  const futureStockPacks = data.futureShipments?.reduce((a, b) => {
    return a + b.dispatchQty;
  }, 0);
  // console.log(currentStockPacks, futureStockPacks);
  const showProduction = (currentStockPacks || 0 + futureStockPacks || 0) < 300;

  return (
    <>
      <PGeneral xlineHeight="20" xfontSize="16">Shipping</PGeneral>
      {stock &&
        stock.map((item, i) => {
          return (
            <PGeneral key={i} xlineHeight="20">
              <SpanBGeneral xfontSize="14">
                {getWhname(item.whUuid)}{" "}
              </SpanBGeneral>
              <SpanLGeneral xfontSize="14">
                {getStockTD(
                  item.dispatchQty,
                  data.unitSize,
                  data.unitSymbol,
                  data.unitName
                )}{" "}
                available
              </SpanLGeneral>
              <SpanBGeneral xfontSize="14" xmarginLeft="14">
                ETA{" "}
              </SpanBGeneral>
              <SpanLGeneral xfontSize="14" xcolor="astGreen">
                {moment(item.eta).format("DD/MM/YYYY")}
              </SpanLGeneral>
            </PGeneral>
          );
        })}
      {stock?.length === 0 && (
        <PGeneral xlineHeight="20">There is no shipment currently.</PGeneral>
      )}

    {planStock?.length>0 && showProduction && <PGeneral xlineHeight="20" xfontSize="16" xmarginTop="20">In Production</PGeneral>}
      {planStock && showProduction &&
        planStock.map((item, i) => {
          const qty = item.completedQty===0 ? item.planQty : item.completedQty - item.shippedQty;
          const date = item.completedDate ? moment(item.completedDate).add(30, "days").format("DD/MM/YYYY") : moment(item.planDate).add(45, "days").format("DD/MM/YYYY");
          return (
            qty>0 && <PGeneral key={i} xlineHeight="20">
              <SpanLGeneral xfontSize="14">
                {getStockTD(
                  qty,
                  data.unitSize,
                  data.unitSymbol,
                  data.unitName
                )}
              </SpanLGeneral>
              <SpanBGeneral xfontSize="14" xmarginLeft="14">
                ETA{" "}
              </SpanBGeneral>
              <SpanLGeneral xfontSize="14" xcolor="astGreen">
                {date}
              </SpanLGeneral>
            </PGeneral>
          );
        })}
      {/* {planStock?.length === 0 && (
        <PGeneral xlineHeight="20">There is no on-going production currently.</PGeneral>
      )} */}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { uuid } = ownProps.match.params;

  return {
    stock: state["futureStock" + uuid],
    planStock: state["planStock" + uuid],
  };
};

export default connect(mapStateToProps)(FutureStock);
