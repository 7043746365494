import React, { useEffect } from "react";
import { connect } from "react-redux";
import TitleCard from "./title-card";
import store, {
  addAsynSimpleListReducer,
  addSingleItemReducer,
  addDraftOrder,
} from "../../store";
import getSimpleList from "../../api/simple-list-api";
import styled from "styled-components";
import {
  fileurl,
  theme,
  H3Title,
  H2Title,
  H5Title,
  H6Title,
  FullWidthContainer,
  SpanBGeneral,
  ExlinkIcon,
  InputGeneral,
  ButtonFuncG,
  ButtonFuncR,
  LinkButtonR,
  FlexButtonGroup,
  maxItemQty,
  popupMaxItemQty,
} from "../../configurations/general-conf";
import decorLabelSrc from "../../resources/decor_label.svg";
import exLinkIcon from "../../resources/icons/ex_link_dark.svg";
import PriceTag from "./price-tag";
import CurrentStock from "./current-stock";
import CurrentStockMP from "./current-stock-mp";
import FutureStock from "./future-stock";
import CurrentStockSCache from "./current-stock-scache";

const HorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

const ThumbListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${theme.proportion * 15}px;

  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

const ThumbItem = styled.li`
  width: ${theme.proportion * 65}px;
  height: ${theme.proportion * 45}px;
  background-image: url(${(props) => props.bgSrc});
  background-size: cover;
  background-position: center;

  border: ${(props) =>
    props.onClick ? null : theme.proportion * 3 + "px solid " + theme.astGreen};
  cursor: ${(props) => (props.onClick ? "pointer" : null)};
`;

const LabeledImgContainer = styled.div`
  width: ${(props) =>
    props.xwidth ? props.xwidth + "px" : theme.proportion * 348 + "px"};
  height: ${(props) =>
    props.xwidth
      ? props.xwidth * 0.75 + "px"
      : theme.proportion * 348 * 0.75 + "px"};
  background-image: url(${(props) => props.bgSrc});
  background-size: cover;
  background-position: center;
  position: relative;

  & div {
    color: ${theme.bright};
    background: ${theme.astGreen};
    display: inline;
    position: absolute;
    right: 0;
    bottom: ${theme.proportion * 15 * theme.mobileResizeFactor}px;
    padding: ${theme.proportion * 4 * theme.mobileResizeFactor}px
      ${theme.proportion * 4 * theme.mobileResizeFactor}px;
    font-family: ${theme.romanFont};
    font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;

    & span {
      font-size: ${theme.proportion * 9 * theme.mobileResizeFactor}px;
    }
  }

  & img {
    position: absolute;
    width: ${theme.proportion * 20 * theme.mobileResizeFactor}px;
    height: ${theme.proportion * 38 * theme.mobileResizeFactor}px;
    bottom: 0;
    right: -${theme.proportion * 19 * theme.mobileResizeFactor}px;
  }

  @media only screen and (max-width: 640px) {
    width: ${theme.proportion * 220 * theme.mobileResizeFactor + "px"};
    margin-bottom: ${theme.proportion * 10 * theme.mobileResizeFactor + "px"};
  }
`;

const MainItemContent = styled.div`
  width: ${theme.proportion * 470}px;
  padding-left: ${theme.proportion * 15}px;

  @media only screen and (max-width: 640px) {
    width: 100%;
    padding-left: 0;
  }
`;

const StockButtonWrapper = styled(FullWidthContainer)`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.proportion * 7 * theme.mobileResizeFactor}px;
  padding: ${theme.proportion * 3 * theme.mobileResizeFactor}px
    ${theme.proportion * 7 * theme.mobileResizeFactor}px;
  margin-top: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
`;

const InfoTitleWarrper = styled(FullWidthContainer)`
  padding: ${theme.proportion * 3 * theme.mobileResizeFactor}px
    ${theme.proportion * 7 * theme.mobileResizeFactor}px;
  margin-top: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
`;

export const StockButton = styled.button`
  background: ${(props) => (props.current ? theme.astGreen : theme.bright)};
  border: 1px solid ${theme.astGreen};
  color: ${(props) => (props.bright ? theme.bright : props.current ? theme.darkerBiege : theme.astGreen)};
  padding: ${theme.proportion * 3 * theme.mobileResizeFactor}px
    ${theme.proportion * 8 * theme.mobileResizeFactor}px;
  border-radius: ${theme.proportion * 5 * theme.mobileResizeFactor}px;
  cursor: pointer;
  font-size: ${theme.proportion * 11 * theme.mobileResizeFactor}px;

  & img {
    vertical-align: initial;
    margin-right: ${theme.proportion * 3 * theme.mobileResizeFactor}px;
    width: ${theme.proportion * 9 * theme.mobileResizeFactor}px;
  }
`;

const OrderNowButton = styled(ButtonFuncG)`
  width: 100%;
`;

const DesSecWrapper = styled(FullWidthContainer)`
  font-size: ${theme.proportion * 11 * theme.mobileResizeFactor}px;
  font-family: ${theme.romanFont};
  padding: ${theme.proportion * 20 * theme.mobileResizeFactor}px 0;
  min-height: ${theme.proportion * 200 * theme.mobileResizeFactor}px;

  @media only screen and (max-width: 640px) {
    font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
  }
`;

const QtyInputWrapper = styled(FullWidthContainer)`
  border-top: 1px solid ${theme.biege};
  padding: ${theme.proportion * 30 * theme.mobileResizeFactor}px 0;
  display: flex;
  justify-content: space-between;
`;

const QtyInputLable = styled.label`
  font-size: ${theme.proportion * 11 * theme.mobileResizeFactor}px;
  font-family: ${theme.romanFont};

  & span {
    text-transform: capitalize;
  }

  @media only screen and (max-width: 640px) {
    font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  }
`;

const QtyInput = styled(InputGeneral)`
  width: ${(props) =>
    theme.proportion * props.xwidth * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 22 * theme.mobileResizeFactor}px;
`;

const LinkedItemList = styled.ul`
  max-height: ${theme.proportion * 620 * theme.mobileResizeFactor}px;
  overflow: auto;
`;

const LinkedItem = styled.li`
  padding: ${theme.proportion * 20 * theme.mobileResizeFactor}px 0
    ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  border-top: 1px solid ${theme.biege};
`;

const LIContentWrapper = styled(FullWidthContainer)`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.proportion * 20 * theme.mobileResizeFactor}px;
  justify-content: space-between;
`;

const LinkedItemContent = styled.div`
  width: ${theme.proportion * 280}px;

  @media only screen and (max-width: 640px) {
    width: ${theme.proportion * 220 * theme.mobileResizeFactor}px;

    & input {
      width: ${theme.proportion * 90 * theme.mobileResizeFactor}px;
    }
  }
`;

const LinkedItemDesc = styled.div`
  padding: ${theme.proportion * 8 * theme.mobileResizeFactor}px 0
    ${theme.proportion * 20 * theme.mobileResizeFactor}px;
  font-family: ${theme.romanFont};
  font-size: ${theme.proportion * 10 * theme.mobileResizeFactor}px;

  & span {
    font-family: ${theme.boldFont};
    font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
    color: ${theme.darkerBiege};
    margin-right: ${theme.proportion * 3 * theme.mobileResizeFactor}px;
  }
`;

const LinkedInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.proportion * 6 * theme.mobileResizeFactor}px;
  justify-content: space-between;
`;

const SingleProduct = (props) => {
  const changeHeroImg = (toKey) => {
    store.dispatch({
      type: "to_hero_" + nameSpace + uuid,
      toKey,
    });
  };

  const changeToInfoTab = (toTab) => {
    store.dispatch({
      type: "to_tab_" + nameSpace + uuid,
      toTab,
    });
  };

  const handleQtyChange = (prd, type, value) => {
    const { productUuid, unitSize } = prd;
    let tempOrdered = [...props.tempOrdered];
    if (!value || parseFloat(value) === 0) {
      tempOrdered = tempOrdered.filter(
        (obj) => obj.productUuid !== productUuid
      );
    } else {
      const quantity =
        type === "qty" ? Math.ceil(value) : Math.ceil(value / (unitSize || 1));

      const exsitIndex = store
        .getState()
        ["draftOrder"]?.saleItems?.findIndex(
          (obj) => obj.productUuid === productUuid
        );
      const qtyAfter =
        quantity +
        (store.getState()["draftOrder"]?.saleItems[exsitIndex]?.quantity || 0);
      qtyAfter > maxItemQty && popupMaxItemQty();

      const amt = parseFloat(
        (quantity * (unitSize || 1)).toFixed(
          unitSize?.toString().split(".")[1]?.length || 0
        )
      );
      const updatedOrder = { productUuid, quantity, amt };
      const orderIndex = tempOrdered.findIndex(
        (obj) => obj.productUuid === productUuid
      );
      if (orderIndex > -1) {
        tempOrdered[orderIndex] = updatedOrder;
      } else {
        tempOrdered =
          uuid === productUuid
            ? [updatedOrder, ...tempOrdered]
            : [...tempOrdered, updatedOrder];
      }
    }
    store.dispatch({
      type: "update_temp_order" + nameSpace + uuid,
      tempOrdered,
    });
  };

  const toProduct = () => {
    // console.log(props.dyPath);
    const to = "/orderonline/product/" + uuid;
    props.history.push(to);
  };

  const addToOrder = () => {
    const lookUpProducts = [data, ...linkedList];
    let saleItems = [];
    tempOrdered.length &&
      tempOrdered.map((item) => {
        const productInfo = lookUpProducts.find(
          (obj) => obj.productUuid === item.productUuid
        );
        return saleItems.push({
          ...item,
          productInfo,
        });
      });
    if (saleItems.length) {
      !store.getState()["draftOrder"] && addDraftOrder(store);
      store.dispatch({
        type: "add_draft_order_items",
        saleItems,
      });
      store.dispatch({
        type: "reset" + nameSpace + uuid,
      });

      // console.log(store.getState()["draftOrder"]);

      viewDraftOrder();
    }
  };

  const viewDraftOrder = () => {
    const to = props.dyPath + "/draftorder";
    props.history.push(to);
  };

  useEffect(() => {
    const { nameSpace, endPoint } = props.conf;
    const { uuid } = props.match.params;

    nameSpace &&
      !store.getState()[nameSpace] &&
      addAsynSimpleListReducer(store, props.conf);
    nameSpace && endPoint && getSimpleList(nameSpace);

    uuid &&
      !store.getState()[nameSpace + uuid] &&
      addSingleItemReducer(store, props.conf, uuid);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [props.conf, props.match.params]);

  const { title, thumbListKeys, labelSuffix, decorLabel } = props.conf;
  const { data, heroImg, tempOrdered, linkedList, infoTab, dyPath } = props;
  const { nameSpace } = props.conf;
  const { uuid } = props.match.params;

  const mainOrdered =
    tempOrdered &&
    tempOrdered.find((obj) => obj.productUuid === data.productUuid);
  const isRender = Object.keys(props.data).length > 0;

  // isRender && console.log(props);

  return (
    <TitleCard
      title={dyPath === "/checklevel" ? "Product Stock Details" : title}
      contentPadding="25"
    >
      {isRender && (
        <>
          <HorWrapper>
            <ThumbListWrapper>
              {thumbListKeys.split("|").map((srcKey, i) => {
                return (
                  <ThumbItem
                    key={`${srcKey}_${i}`}
                    bgSrc={data[srcKey] ? fileurl + data[srcKey] : null}
                    onClick={
                      heroImg !== srcKey
                        ? () => {
                            changeHeroImg(srcKey);
                          }
                        : null
                    }
                  ></ThumbItem>
                );
              })}
            </ThumbListWrapper>
            <LabeledImgContainer
              bgSrc={data[heroImg] ? fileurl + data[heroImg] : null}
            >
              {dyPath !== "/checklevel" && (
                <PriceTag
                  unitSymbol={data["unitSymbol"]?.split("|")[0]}
                  unitNanme={data["unitName"]?.split("|")[0]}
                  unitSize={data.unitSize}
                  perText={labelSuffix}
                  price={data.price1}
                  priceBulk={data.price2}
                  bulkLimiter={data.bulkQty}
                  currentQty={mainOrdered?.quantity}
                />
              )}
              {dyPath !== "/checklevel" && decorLabel && (
                <img src={decorLabelSrc} alt="Label Decor" />
              )}
            </LabeledImgContainer>
            <MainItemContent>
              <H3Title xfontSize="16">{data["productCategoryName"]}</H3Title>
              <H2Title xfontSize="24" xmarginTop="10" xlineHeight="20">
                {data["productName"] ||
                  data["productDisplayName"] ||
                  data["productColor"]}
              </H2Title>
              {dyPath !== "/checklevel" && (
                <StockButtonWrapper xbackgroundColor="biege">
                  <StockButton
                    current={infoTab === "specs"}
                    onClick={
                      infoTab !== "specs"
                        ? () => {
                            changeToInfoTab("specs");
                          }
                        : null
                    }
                  >
                    <ExlinkIcon src={exLinkIcon} />
                    <SpanBGeneral>Specifications</SpanBGeneral>
                  </StockButton>
                  <StockButton
                    current={infoTab === "shipment"}
                    onClick={
                      infoTab !== "shipment"
                        ? () => {
                            changeToInfoTab("shipment");
                          }
                        : null
                    }
                  >
                    <ExlinkIcon src={exLinkIcon} />
                    <SpanBGeneral>Incoming Stock</SpanBGeneral>
                  </StockButton>
                  <StockButton
                    current={infoTab === "stock"}
                    onClick={
                      infoTab !== "stock"
                        ? () => {
                            changeToInfoTab("stock");
                          }
                        : null
                    }
                  >
                    <ExlinkIcon src={exLinkIcon} />
                    <SpanBGeneral>Stock</SpanBGeneral>
                  </StockButton>
                </StockButtonWrapper>
              )}
              {dyPath !== "/checklevel" && (
                <DesSecWrapper>
                  {infoTab === "specs" &&
                    data["notes"]?.split("\n").map(function (item, idx) {
                      return (
                        <span key={idx}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  {infoTab === "shipment" && <FutureStock {...props} />}
                  {infoTab === "stock" && <CurrentStock {...props} />}
                </DesSecWrapper>
              )}
              {dyPath !== "/checklevel" && (
                <QtyInputWrapper>
                  <div>
                    <QtyInputLable htmlFor={`iQty_${data["productUuid"]}`}>
                      <span>
                        {data["unitName"]?.split("|")[1] || "Quantity"}
                      </span>{" "}
                      Required (qty):{" "}
                    </QtyInputLable>
                    <QtyInput
                      type="number"
                      step="1"
                      min="0"
                      id={`iQty_${data["productUuid"]}`}
                      name={`iQty_${data["productUuid"]}`}
                      onBlur={(e) =>
                        handleQtyChange(data, "qty", e.target.value)
                      }
                      onKeyPress={(e) => {
                        e.key === "Enter" &&
                          handleQtyChange(data, "qty", e.target.value);
                      }}
                      key={`iQty_${data["productUuid"]}_${mainOrdered?.quantity}`}
                      defaultValue={mainOrdered?.quantity}
                      xwidth="90"
                    />
                  </div>
                  <div>
                    <QtyInputLable htmlFor={`iAmt_${data["productUuid"]}`}>
                      <span>{data["volumeName"] || "Amount"}</span> Required (
                      {data["unitSymbol"] || "unit"}):{" "}
                    </QtyInputLable>
                    <QtyInput
                      type="number"
                      min="0"
                      id={`iAmt_${data["productUuid"]}`}
                      name={`iAmt_${data["productUuid"]}`}
                      onBlur={(e) =>
                        handleQtyChange(data, "amt", e.target.value)
                      }
                      onKeyPress={(e) => {
                        e.key === "Enter" &&
                          handleQtyChange(data, "amt", e.target.value);
                      }}
                      key={`iAmt_${data["productUuid"]}_${
                        mainOrdered?.amt
                      }_${Date.now()}`}
                      defaultValue={mainOrdered?.amt}
                      xwidth="90"
                    />
                  </div>
                </QtyInputWrapper>
              )}
              {dyPath !== "/checklevel" && linkedList.length > 0 && (
                <LinkedItemList>
                  {linkedList.map((item, i) => {
                    const itemOrdered =
                      tempOrdered &&
                      tempOrdered.find(
                        (obj) => obj.productUuid === item.productUuid
                      );
                    return (
                      <LinkedItem key={`linked_item_${item["productUuid"]}`}>
                        {i === 0 && (
                          <div>
                            <H3Title xfontSize="16">
                              Add product{" "}
                              <SpanBGeneral xfontSize="11">
                                *closest match
                              </SpanBGeneral>
                            </H3Title>
                            {/* <H3Title xfontSize="12">*closest match</H3Title> */}
                          </div>
                        )}
                        <LIContentWrapper>
                          <LabeledImgContainer
                            bgSrc={fileurl + item["imageUrl"]}
                            xwidth="150"
                          >
                            <PriceTag
                              unitSymbol={item["unitSymbol"]?.split("|")[0]}
                              unitNanme={item["unitName"]?.split("|")[0]}
                              unitSize={item.unitSize}
                              perText={labelSuffix}
                              price={item.price1}
                              priceBulk={item.price2}
                              bulkLimiter={item.bulkQty}
                              currentQty={itemOrdered?.quantity}
                            />
                            {decorLabel && (
                              <img src={decorLabelSrc} alt="Label Decor" />
                            )}
                          </LabeledImgContainer>
                          <LinkedItemContent>
                            <H5Title xfontSize="12">
                              {item["productName"] ||
                                item["productDisplayName"] ||
                                item["productColor"]}
                            </H5Title>
                            <H6Title xfontSize="12" xcolor="darkerBiege">
                              {item["productCategoryName"]}
                            </H6Title>
                            <LinkedItemDesc>
                              <span>Dimension</span>
                              {item["productDimension"]}
                            </LinkedItemDesc>
                            <LinkedInputWrapper>
                              <QtyInputLable
                                htmlFor={`iQty_${item["productUuid"]}`}
                              >
                                <span>
                                  {item["unitName"]?.split("|")[1] ||
                                    "Quantity"}
                                </span>{" "}
                                Required (qty):{" "}
                              </QtyInputLable>
                              <QtyInput
                                type="number"
                                step="1"
                                min="0"
                                id={`iQty_${item["productUuid"]}`}
                                name={`iQty_${item["productUuid"]}`}
                                onBlur={(e) =>
                                  handleQtyChange(item, "qty", e.target.value)
                                }
                                onKeyPress={(e) => {
                                  e.key === "Enter" &&
                                    handleQtyChange(
                                      item,
                                      "qty",
                                      e.target.value
                                    );
                                }}
                                key={`iQty_${item["productUuid"]}_${itemOrdered?.quantity}`}
                                defaultValue={itemOrdered?.quantity}
                                xwidth="150"
                              />
                            </LinkedInputWrapper>
                            <LinkedInputWrapper>
                              <QtyInputLable
                                htmlFor={`iAmt_${item["productUuid"]}`}
                              >
                                <span>{item["volumeName"] || "Amount"}</span>{" "}
                                Required ({item["unitSymbol"] || "unit"}):{" "}
                              </QtyInputLable>
                              <QtyInput
                                type="number"
                                min="0"
                                id={`iAmt_${item["productUuid"]}`}
                                name={`iAmt_${item["productUuid"]}`}
                                onBlur={(e) =>
                                  handleQtyChange(item, "amt", e.target.value)
                                }
                                onKeyPress={(e) => {
                                  e.key === "Enter" &&
                                    handleQtyChange(
                                      item,
                                      "amt",
                                      e.target.value
                                    );
                                }}
                                key={`iAmt_${item["productUuid"]}_${
                                  itemOrdered?.amt
                                }_${Date.now()}`}
                                defaultValue={itemOrdered?.amt}
                                xwidth="150"
                              />
                            </LinkedInputWrapper>
                          </LinkedItemContent>
                        </LIContentWrapper>
                        <CurrentStockMP product={item} />
                      </LinkedItem>
                    );
                  })}
                </LinkedItemList>
              )}
              {dyPath === "/checklevel" && (
                <>
                  <InfoTitleWarrper xbackgroundColor="biege">
                    <SpanBGeneral>Stock Levels</SpanBGeneral>
                  </InfoTitleWarrper>
                  <DesSecWrapper>
                    <CurrentStockSCache data={data} />
                  </DesSecWrapper>
                </>
              )}
              {dyPath === "/checklevel" && (
                <>
                  <InfoTitleWarrper xbackgroundColor="biege">
                    <SpanBGeneral>Next Shipment(s)</SpanBGeneral>
                  </InfoTitleWarrper>
                  <DesSecWrapper>
                    <FutureStock {...props} />
                  </DesSecWrapper>
                </>
              )}
              {dyPath === "/checklevel" &&
                JSON.parse(
                  window.localStorage.getItem("company")
                ).oosLogin?.indexOf("Stock Check") < 0 && (
                  <OrderNowButton onClick={toProduct}>ORDER NOW</OrderNowButton>
                )}
            </MainItemContent>
          </HorWrapper>
          {dyPath !== "/checklevel" && (
            <FlexButtonGroup>
              <LinkButtonR
                target={data.info5 ? "_blank" : "_self"}
                href={data.info5 ? data.info5 : "#"}
              >
                Download Specifications
              </LinkButtonR>
              <div>
                {store.getState()["draftOrder"]?.saleItems?.length > 0 && (
                  <ButtonFuncR onClick={viewDraftOrder}>
                    View Order Summary
                  </ButtonFuncR>
                )}
                <ButtonFuncG xmarginLeft="10" onClick={addToOrder}>
                  Add to Order
                </ButtonFuncG>
              </div>
            </FlexButtonGroup>
          )}
        </>
      )}
    </TitleCard>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { nameSpace, filterKey, listKey, matchKey } = ownProps.conf;
  const { uuid } = ownProps.match.params;

  let data = {};
  let tempListAll = [];
  let linkedList = [];
  if (uuid && state[nameSpace]?.list?.length > 0) {
    state[nameSpace].list.map((listItem) => {
      // let foundLinked = listItem[listKey].filter(
      //   (obj) =>
      //     obj[matchKey]?.indexOf(uuid) > -1 ||
      //     obj[matchKey] === "all" ||
      //     obj[matchKey] === "All"
      // );
      // foundLinked = foundLinked.filter((obj) => obj.productUuid !== uuid);
      // linkedList = [...linkedList, ...foundLinked];
      tempListAll = [...tempListAll, ...listItem[listKey]];
      return (data =
        listItem[listKey].find((obj) => obj[filterKey] === uuid) || data);
    });
  }
  // console.log(tempListAll);
  // console.log(data[matchKey]);
  if (data[matchKey] && tempListAll.length > 0) {
    // linkedList = tempListAll.filter(
    //   (obj) => data[matchKey].indexOf(obj.productUuid) > -1
    // );
    data[matchKey].split(",").map((item) => {
      return (linkedList = [
        ...linkedList,
        ...tempListAll.filter((obj) => obj.productUuid === item),
      ]);
    });
  }
  return {
    data,
    linkedList,
    heroImg: state[nameSpace + uuid]?.heroImg,
    tempOrdered: state[nameSpace + uuid]?.tempOrdered,
    infoTab: state[nameSpace + uuid]?.infoTab,
  };
};

export default connect(mapStateToProps)(SingleProduct);
