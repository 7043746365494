import axios from "axios";
import store, { addAsynSimpleListReducer } from "../store";
import mainPrdCatsConf from "../configurations/thumb-lists/main-prd-cats";
import samplePrdCatsConf from "../configurations/thumb-lists/sample-prod-cats";
import getSimpleList from "./simple-list-api";

const getSingleItem = async (nameSpace) => {
  const { endPoint } = store.getState()[nameSpace];
  // console.log(endPoint);

  try {
    const res = await axios.get(endPoint);
    console.log(res.data);
  } catch (error) {
    console.log(error);
  }
};

export default getSingleItem;

export const getSingleOrder = async (uuid, dyPath) => {
  const mode = dyPath === "/sampleorder/createnew" ? "sample" : "main";
  const resturl =
    mode === "sample" ? "/sampleorders/sampleitem/" : "/saleorders/";
  const res = await axios.get(resturl + uuid);
  let data = res.data?.objs[0];
  // console.log(data);
  if (data) {
    const prodCatsConf =
      mode === "sample" ? samplePrdCatsConf : mainPrdCatsConf;
    const { nameSpace } = prodCatsConf;
    !store.getState()[nameSpace] &&
      addAsynSimpleListReducer(store, prodCatsConf);
    if(nameSpace === "prdsmpc") {
      const sampleList = JSON.parse(window.localStorage.getItem("sampleList"));
      sampleList && store.dispatch({ type: "get_" + nameSpace, data: sampleList });
    }
    if (!store.getState()[nameSpace].list) {
      await getSimpleList(nameSpace);
    }
    // console.log(store.getState()[nameSpace].list);
    let productsDic = [];
    store.getState()[nameSpace].list?.map((listItem) => {
      // if (listItem.sampleCombinations) {
      //   listItem.sampleCombinations.map((sampleCombination) => {
      //     return (productsDic = [
      //       ...productsDic,
      //       ...sampleCombination.products,
      //     ]);
      //   });
      // } else {
      //   return (productsDic = [...productsDic, ...listItem.products]);
      // }
      // return null;
      if (listItem.sampleCombinations) {
        return (productsDic = [...productsDic, ...listItem.sampleCombinations]);
      } else {
        return (productsDic = [...productsDic, ...listItem.products]);
      }
    });
    // console.log(productsDic);
    data.saleItems?.map((saleItem) => {
      const foundProduct = productsDic.find(
        (obj) => obj.productUuid === saleItem.productUuid
      );
      delete saleItem.product;
      saleItem.productInfo = foundProduct;
      saleItem.amt = parseFloat(
        (saleItem.quantity * (foundProduct?.unitSize || 1)).toFixed(
          foundProduct?.unitSize?.toString().split(".")[1]?.length || 0
        )
      );
      return null;
    });
    data.sampleItems?.map((sampleItem) => {
      const foundSample = productsDic.find(
        (obj) => obj.sampleCombinationUuid === sampleItem.sampleCombinationUuid
      );
      delete sampleItem.sampleCombination;
      sampleItem.smapleInfo = foundSample;
      return null;
    });
    // console.log(data);
    store.dispatch({
      type: "load_draft_order",
      data,
    });
  }
};

export const getProductStock = async (uuid) => {
  const res = await axios.get("/products/ooscurrentstock/" + uuid);
  const data = res.data?.objs;
  // console.log(data);
  // data &&
  //   store.dispatch({
  //     type: "get_currentStock" + uuid,
  //     data,
  //   });
  if (data) {
    store.dispatch({
      type: "get_currentStock" + uuid,
      data,
    });
  }
};

export const getFutureStock = async (uuid) => {
  const res = await axios.get("/products/oosfuturestock/" + uuid);
  const data = res.data?.objs;
  // console.log(uuid, data);
  if (data) {
    store.dispatch({
      type: "get_futureStock" + uuid,
      data,
    });
  }
  const resPlans = await axios.get("productionplans?productUuid="+uuid+"&poStatus=2&status=0,1,2&orderBy=plan_date");
  const dataPlans = resPlans.data?.objs;
  // console.log(uuid, dataPlans);
  if (dataPlans) {
    store.dispatch({
      type: "get_planStock" + uuid,
      data: dataPlans,
    });
  }
};
